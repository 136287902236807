


































import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default defineComponent({
  name: 'UOttawaNewPrelab5Q4',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        procedureAnswer: null,
        language: 'en',
      },
      questions: [
        {
          en: 'In the lab, do you need to treat catalase differently than KI? Explain in 2 sentences or less.',
          fr: 'Au laboratoire, faut-il traiter la catalase différemment du KI ? Expliquez votre réponse en 2 phrases ou moins.',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
